import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import SliceZone from '../components/SliceZone';
import GridItem from '../components/slices/GridItem/GridItem';

const TestPlaygroundTemplate = ({ data }) => {
  if (!data) return null;

  const testPlaygroundContent = data.prismicTestPlayground;
  const testPlayground = testPlaygroundContent.data || {};
  const allTestPlaygroundPages = data.allPrismicTestPlaygroundPage.nodes;

  const { lang, type, url } = testPlaygroundContent;
  const alternateLanguages = testPlaygroundContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const navigationMenu = data.prismicNavigation || {};
  const footerMenu = data.prismicFooter || {};
  const metaTitle = 'Test Playground | CloudArmy';
  return (
    <Layout
      navigationMenu={navigationMenu.data}
      activeDocMeta={activeDoc}
      footerMenu={footerMenu.data}
      metaTitle={metaTitle}
    >
      <SliceZone slices={testPlayground.body} />
      <GridItem posts={allTestPlaygroundPages} />
    </Layout>
  );
};

export const query = graphql`
query testPlaygroundQuery($uid: String, $lang: String) {
  prismicTestPlayground(uid: {eq: $uid}, lang: {eq: $lang}) {
    url
    uid
    type
    lang
    alternate_languages {
      id
      type
      lang
      uid
    }
    _previewable  
    data {
      section
      body {
          ... on PrismicTestPlaygroundDataBodyTextWithAction {
            id
            slice_label
            slice_type
            primary {
              alignment
              title {
                html
                raw
                text
              }
              text_width
              text {
                html
                raw
                text
              }
              title_color
            }
          }
          ... on PrismicTestPlaygroundDataBodyTextInfo {
            slice_label
            slice_type
            id
            primary {
              description_alignment
              description_color
              description_html {
                html
                raw
                text
              }
              title_alignment
              title_color
              width
              title {
                html
                raw
                text
              }
              pdf_text {
                html
                raw
                text
              }
              styled_html {
                alt
                copyright
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              pdf_hyperlink {
                url
              }
              description {
                html
                raw
                text
              }
            }
          }
        }
    }
  }
  allPrismicTestPlaygroundPage {
    nodes {
      _previewable
      id
      url
      tags
      data {
        display_title {
          html
          raw
          text
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
        body {
          ... on PrismicTestPlaygroundPageDataBodyFullWidthMedia {
            id
            slice_label
            slice_type
            primary {
              title_font_size
              title {
                html
                raw
                text
              }
              text_position
              media {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                  publicURL
                }
              }
              margin_top
              description_position
              description {
                html
                raw
                text
              }
            }
          }
          ... on PrismicTestPlaygroundPageDataBodyTextWithAction {
            id
            slice_label
            slice_type
            primary {
              alignment
              title_color
              title {
                html
                raw
                text
              }
              text_width
              text {
                html
                raw
                text
              }
            }
            items {
              link_title {
                html
                raw
                text
              }
              link_opens_to_a_new_tab
              link {
                url
              }
            }
          }
        }
      }
    }
    pageInfo {
      currentPage
      pageCount
    }
  }
  prismicNavigation(lang: {eq: $lang}) {
    ...NavigationFragment
  }
  prismicFooter(lang: {eq: $lang}) {
      ...FooterFragment
  }
}
`;

export default withPrismicPreview(TestPlaygroundTemplate);
